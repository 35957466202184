<template>
	<div class="card">
    <div class="card-body">
      <div class="row"><div class="col"><h4><span class="bg-secondary"># {{$t('common.report_im_ex_remain')}}</span></h4></div></div>
      <div class="row d-flex justify-content-between align-items-center">
        <div :class="{'col-md-3': is_admin && cocon && this.ls_branch_ddl.length>0,'':!(is_admin && cocon && this.ls_branch_ddl.length>0)}" v-if="is_admin && cocon && this.ls_branch_ddl.length>0">
          <label for="validationBranch">{{$t('common.branch')}}</label>
          <multiselect v-model="branchs" :tag-placeholder="$t('common.select_branch')"
            :placeholder="$t('common.placeholder_branch')" label="name" :select-label="$t('common.enter_to_select')"
            track-by="id" :options="ls_branch_ddl" :multiple="true"
            :taggable="true" :deselect-label="$t('common.enter_to_remove')"></multiselect>
        </div>

        <div :class="{'col-md-3': is_admin && cocon && this.ls_branch_ddl.length>0,'col-md-4':!(is_admin && cocon && this.ls_branch_ddl.length>0)}">
          <label for="validationNgayFromTo">{{$t('common.from_date')}}</label>
          <date-picker v-model="from_date" :config="options" class="date-picker-height"></date-picker>
        </div>
        <div :class="{'col-md-3': is_admin && cocon && this.ls_branch_ddl.length>0,'col-md-4':!(is_admin && cocon && this.ls_branch_ddl.length>0)}">
          <label for="validationNgayFromTo">{{$t('common.to_date')}}</label>
          <date-picker v-model="to_date" :config="options" class="date-picker-height"></date-picker>
        </div>
        <div class="col-md-3 pull-right">
          <a href="#" @click="apply_filter" class="btn btn-primary mr-1"
          style="margin-top: 20px;" id="validationSubmit"><i class="fa fa-search" aria-hidden="true"></i> {{$t('common.search')}}</a>
          <a href="#" @click="export_pdf()" class="btn btn-danger mr-1" v-b-modal.pdf_baocaotong_review_screen
          style="margin-top: 20px;" id="validationSubmit"><i class="fa fa-file-pdf-o" aria-hidden="true"></i> Pdf</a>
          <!-- <a href="#" @click="export_excel()" class="btn btn-warning"
          style="margin-top: 20px;" id="validationSubmit"><i class="fa fa-file-excel-o" aria-hidden="true"></i> Excel</a> -->
        </div>
      </div>
      <div class="row mt-2">
        <div class="col">
          <b-table striped hover responsive
            :items="filteredAndSortedData_report" :fields="fields" :current-page="page"
            :per-page="0"  @row-clicked="rowClicked">
          <template slot="top-row" slot-scope="{ fields }">
            <td v-for="field in fields" :key="field.key">
              <input v-model="filteredAndSortedData[field.key]" 
              v-if="field.key && field.label" :placeholder="field.label">
            </td>
          </template>
            <template slot="branch_name" slot-scope="data">
              <label v-if="data.item.branch_name">{{data.item.branch_name}}</label>
              <label v-else>{{data.item.company_name}}</label>
            </template>

            <template slot="unit_name" slot-scope="data">
              <label v-if="data.item.unit_name">({{JSON.parse(data.item.unit_name)[$i18n.locale]}})</label>
              <label v-else></label>
            </template>

            <template slot="import" slot-scope="data">
              <label v-if="data.item.import!=0">{{data.item.import}} ({{JSON.parse(data.item.unit_name)[$i18n.locale]}})</label>
              <label v-else>0</label>
            </template>

            <template slot="order" slot-scope="data">
              <label v-if="data.item.order!=0">{{data.item.order}} ({{JSON.parse(data.item.unit_name)[$i18n.locale]}})</label>
              <label v-else>0</label>
            </template>

            <template slot="remain" slot-scope="data">
              <label v-if="data.item.remain!=0">{{data.item.remain}} ({{JSON.parse(data.item.unit_name)[$i18n.locale]}})</label>
              <label v-else>0</label>
            </template>

          </b-table>
        </div>
      </div>

      <div class="row pull-right">
        <div class="col">
          <pager :frame="8"
                :pageCount="page_count"
                :page="page"
                @change="change_page">
          </pager>
        </div>
      </div>
    </div>
    <b-modal id="pdf_baocaotong_review_screen" :title="$t('common.report')" size="lg" header-bg-variant="info" no-body ok-only>
      <div id="product_im_ex_pdf" style="height:450px;"></div>
    </b-modal>
	</div>
</template>
<style scoped>
  label{
    margin-bottom: 0rem;
  }
  input{
    min-height: 43px;
  }
</style>
<script>
import pdfobj from 'pdfobject'
import { validationMixin } from 'vuelidate'
import { required, minLength,maxLength,between,numeric,requiredIf } from 'vuelidate/lib/validators'
import Loading from 'vue-loading-overlay'
import datePicker from 'vue-bootstrap-datetimepicker'
import {Role} from '../../helpers/role'
import moment from 'moment'
import Multiselect from 'vue-multiselect'
import { Business } from '../../helpers/business'
import { mapGetters, mapActions } from 'vuex'
import Pager from '../Pager'

export default {
	components: {Loading,datePicker,Multiselect,Pager},
	data () {
		return {
			search_form:{
        company_id:null,
        company_cocon:false,
				ls_branch:[],
				tungay:moment().clone().startOf('month').format('DD-MM-YYYY'),
        denngay:moment().format('DD-MM-YYYY')
			},
			options: {
				format: 'DD-MM-YYYY',
				useCurrent: false,
				showClear: true,
        showClose: true
			}
		}
	},
	validations: {
    search_form: {
      tungay: {
        required
      },
      denngay: {
        required
      }
    }
  },
	computed: {
    ...mapGetters('st_product_report', ['ls_product','from_date','to_date','branchs','company_id','page_count','page','per_page','has_son']),
    is_admin () {
      let user= this.$store.state.st_authentication.user
      return user&& user.role==Role.Admin
    },
    is_super () {
      let user= this.$store.state.st_authentication.user
      return user&& user.role==Role.Super_Admin
    },
    is_branch () {
      let user= this.$store.state.st_authentication.user
      return user&& user.role==Role.Branch_Admin
    },
    cocon () {
      let user= this.$store.state.st_authentication.user
      return Boolean(user.company.cocon)
    },
    business () {
      let user= this.$store.state.st_authentication.user
      return user&&user.company.business
    },
    user_login () {
      return this.$store.state.st_authentication.user
    },
    ls_branch_ddl(){
      return this.$store.state.st_branch.ls_branch_ddl
    },
    fields(){
      let fields=[]
      let user=this.$store.state.st_authentication.user
      fields.push({key: 'branch_name',label:this.$t('common.company_branch'),sortable: true, variant:'info'})
      if(this.is_super){
        fields.push({key: 'business',label:this.$t('common.business'),sortable: true})
      }

      fields.push({key: 'name',label:this.$t('common.name'),sortable: true})
      fields.push({key: 'unit_name',label:this.$t('common.unit'),sortable: true})

      fields.push({key: 'import',label:this.$t('common.import'),sortable: true})
      fields.push({key: 'order',label:this.$t('common.export'),sortable: true})
      fields.push({key: 'remain',label:this.$t('common.remain'),sortable: true})

      return fields
    },

    from_date:{
      get: function(){
        return this.$store.state.st_product_report.from_date
      },
      set: function(date){
        this.$store.commit('st_product_report/set_from_date',date)
      }
    },

    to_date:{
      get: function(){
        return this.$store.state.st_product_report.to_date
      },
      set: function(date){
        this.$store.commit('st_product_report/set_to_date',date)
      }
    },

    branchs:{
      get: function(){
        return this.$store.state.st_product_report.branchs
      },
      set: function(ls){
        this.$store.commit('st_product_report/set_branchs',ls)
      }
    },

    company_id:{
      get: function(){
        return this.$store.state.st_product_report.company_id
      },
      set: function(id){
        this.$store.commit('st_product_report/set_company_id',id)
      }
    },

    has_son:{
      get: function(){
        return this.$store.state.st_product_report.has_son
      },
      set: function(flag){
        this.$store.commit('st_product_report/set_has_son',flag)
      }
    },

    filteredAndSortedData_report:{
      get: function(){
        let vm=this
        let result = vm.$store.state.st_product_report.ls_product
        debugger
        return result
      }
    }
  },
	mounted(){
    let vm=this
    vm.company_id=vm.user_login.company_id
    vm.has_son=vm.user_login.company.cocon
    debugger
    if(vm.user_login.role==Role.Branch_Admin){
      vm.branchs=[{id:vm.user_login.branch_id}]
    }
		if(vm.is_admin && vm.user_login.company.cocon){
			vm.get_ls_branch(vm.user_login.company_id)
    }
    vm.get_ls_product()
	},
	methods: {
    ...mapActions('st_product_report', ['get_ls_product','apply_filter','change_page']),
    rowClicked (item) {},
		get_ls_branch(company_id){
      this.$store.dispatch('st_branch/get_ls_branch_ddl',company_id)
		},
		export_pdf() {
      var app = this
      app.$store.dispatch('st_product_report/pdf_product_im_ex',app.$i18n.locale).then((response) => {
        if(response && response.data){
          let blob = new Blob([response.data], { type: 'application/pdf' })
          pdfobj.embed(window.URL.createObjectURL(blob), "#product_im_ex_pdf")
        }
      })
    },
    export_excel(){
      var app = this
      app.$store.dispatch('st_product_report/excel_product_im_ex').then((response) => {
        if(response && response.data){
          const url = URL.createObjectURL(new Blob([response.data], {
            type: 'application/vnd.ms-excel'
          }))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'Báo cáo tồn kho')
          document.body.appendChild(link)
          link.click()
        }
      })
    }
	}
}
</script>

<style scoped>
	.pdfobject-container { width: 100%; height: 80rem; border: 1rem solid rgba(0,0,0,.1); }
</style>
